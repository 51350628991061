import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, Button,Avatar } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import abi from '../../utils/Identeefi.json';
import QRCode from 'qrcode.react';
import { ethers } from 'ethers';
import bgImg from '../../img/bg2.png';

const getEthereumObject = () => window.ethereum;

const MyProducts = () => {
    const [ownedSerialNumbers, setOwnedSerialNumbers] = useState([]);
    const [currentAccount, setCurrentAccount] = useState("");
    const [productData, setProductData] = useState([]);
    const [error, setError] = useState(null);

    const CONTRACT_ADDRESS = '0x2cea51a7fd389f132f720da3e5c853b0e46120c1'; // Replace with your contract address
    const CONTRACT_ABI = abi.abi;
    const [qrData, setQrData] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const connectWallet = async () => {
        try {
            const ethereum = getEthereumObject();
            if (!ethereum) {
                alert("Get MetaMask!");
                return;
            }

            const accounts = await ethereum.request({
                method: "eth_requestAccounts",
            });

            console.log("Connected", accounts[0]);
            setCurrentAccount(accounts[0]);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        console.log("useEffect 1");
        findMetaMaskAccount().then((account) => {
            if (account !== null) {
                setCurrentAccount(account);
            }
        })
    }, []);

    const findMetaMaskAccount = async () => {
        try {
            const ethereum = getEthereumObject();

            if (!ethereum) {
                console.error("Make sure you have Metamask!");
                return null;
            }

            console.log("We have the Ethereum object", ethereum);
            const accounts = await ethereum.request({ method: "eth_accounts" });

            if (accounts.length !== 0) {
                const account = accounts[0];
                console.log("Found an authorized account:", account);
                return account;
            } else {
                console.error("No authorized account found");
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const getOwnedSerialNumbers = async () => {
        try {
            const { ethereum } = window;

            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = await provider.getSigner();
                const productContract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);

                const address = await signer.getAddress();
                const serialNumbers = await productContract.getOwnedSerialNumbers(address);
                console.log(serialNumbers);
                setOwnedSerialNumbers(serialNumbers);
                setError(null);
                fetchProductData(serialNumbers);
            } else {
                console.log("Ethereum object doesn't exist!");
            }
        } catch (error) {
            console.error("Error fetching owned serial numbers:", error);
            setError("Error fetching owned serial numbers. Please try again.");
        }
    };

    const setData = (d) => {
        console.log("product data: ", d);

        const productDetails = d.map(product => {
            const serialNumber = product[0];
            const name = product[1];
            const brand = product[2];
            const description = product[3].replace(/;/g, ",");
            const image = product[4];
            const price = product[5];
            const owner = product[6];
            const contreview = product[7];

            return {
                serialNumber,
                name,
                brand,
                description,
                image,
                price,
                owner,
                contreview
            };
        });

        setProductData(productDetails);
    };

    const fetchProductData = async (serialNumbers) => {
        try {
            const { ethereum } = window;

            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = await provider.getSigner();
                const productContract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
                console.log("here ");
                console.log(serialNumbers);
                const productPromises = serialNumbers.map(serial => productContract.getProduct(serial));

                const products = await Promise.all(productPromises);
                console.log(products);
                setData(products);
                setError(null);
            } else {
                console.log("Ethereum object doesn't exist!");
            }
        } catch (error) {
            console.error("Error fetching product data:", error);
            setError("Error fetching product data. Please try again.");
        }
    };
    
    const generateQRCode = (serialNumber) => {
      const data = `${CONTRACT_ADDRESS},${serialNumber}`; // Ensure unique data for each product
      console.log("Generated QR Code:", data);
      return data; // Return the unique QR data
    };

    const handleDownloadQrCode = async (serialNumber) => {
      const canvas = document.getElementById(`QRCode-${serialNumber}`); // Use unique ID for each product's QR code
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
    
      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${serialNumber}.png`; // Use serial number for file name
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };
    const handleBack = () => {
      navigate(-1)
    }
    
    const SubmitReview = async (serialNumber) => {
        const serialNumberForReview = serialNumber;
        navigate('/my-products-give-review', { state: { serialNumberForReview } });
    };

    return (
      <Box style={{ 
        backgroundImage: `url(${bgImg})`, 
        backgroundSize: 'cover', // Apply background-size: cover
        minHeight: '100vh', 
      }}>
        {/* Header Row */}
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '30px' }}>
          {/* Back Button */}
          <Button 
    onClick={handleBack} 
    style={{ 
        background: 'none', 
        color: 'white', 
        marginBottom: '20px', 
        alignSelf: 'flex-start', 
        border: 'none',
        fontSize: '1.1rem', // Increase button font size
        transition: 'transform 0.3s, color 0.3s', // Add transition effect
        '&:hover': {
            transform: 'scale(1.05)', // Increase size on hover
            color: '#ffd700', // Change color on hover
        }
    }} 
    color="primary" 
    variant="outlined"
>
    Back
</Button>
    
          {/* Title */}
          <Typography variant="h4" style={{ color: 'white', textAlign: 'center', flexGrow: 1 }}>My Owned Products</Typography>
    
          {/* Fetch Owned Serial Numbers Button */}
          <Button variant="contained" color="primary" onClick={getOwnedSerialNumbers} style={{ fontSize: '1.2rem', padding: '10px 20px' }}>
            Fetch Owned Prodcuts
          </Button>
        </Box>
    
        {/* Product Cards */}
        {productData.length > 0 && (
          <Box>
           
            <Box display="flex" flexWrap="wrap" justifyContent="center">
              {productData.map((product, index) => (
                <Paper
                  key={index}
                  elevation={2}
                  style={{ padding: '10px', margin: '10px', width: '22%', textAlign: 'center' }} // Adjusted margin for increased spacing
                >
                  <Typography variant="subtitle1">Product {index + 1}</Typography>
                  <Typography variant="body1">Serial Number: {product.serialNumber}</Typography>
                  <Typography variant="body1">
                    {product.contreview ? `Review: ${product.contreview}` : 'Review not given'}
                  </Typography>
                  <Avatar
                    alt={product.name}
                    src={`https://backend-node-hmry.onrender.com/file/product/${product.image}`}
                    sx={{
                      width: 100,
                      height: 100,
                      margin: 'auto',
                      marginBottom: '3%',
                      backgroundColor: '#3f51b5',
                    }}
                  />
    
                  {/* Generate QR Code for this specific product */}
                  <QRCode
                    value={`${CONTRACT_ADDRESS},${product.serialNumber}`}
                    id={`QRCode-${product.serialNumber}`}
                    size={128}
                  />
    
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleDownloadQrCode(product.serialNumber)} // Pass serial number for download
                    >
                      Download QR Code
                    </Button>
    
                    {/* Conditionally show "Give Review" button if no review is given */}
                    {!product.contreview && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => SubmitReview(product.serialNumber)}
                      >
                        Give Review
                      </Button>
                    )}
                  </Box>
                </Paper>
              ))}
            </Box>
          </Box>
        )}
    
        {/* Connect Wallet Button */}
        {!currentAccount && (
          <Button className="btns" onClick={connectWallet}>
            Connect Wallet
          </Button>
        )}
      </Box>
    );
    
    
    
};

export default MyProducts;
