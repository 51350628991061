import React, { useState, useEffect } from 'react';
import { LinkButton } from '../LinkButton';
import { Box, Button as Btn, Snackbar, Alert } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import '../Admin.css';
import { Button } from '../Button';

    const getEthereumObject = () => window.ethereum;

    const findMetaMaskAccount = async () => {
        try {
            const ethereum = getEthereumObject();

            /*
             * First make sure we have access to the Ethereum object.
             */
            if (!ethereum) {
                console.error("Make sure you have Metamask!");
                return null;
            }

            console.log("We have the Ethereum object", ethereum);
            const accounts = await ethereum.request({ method: "eth_accounts" });

            if (accounts.length !== 0) {
                const account = accounts[0];
                console.log("Found an authorized account:", account);
                return account;
            } else {
                console.error("No authorized account found");
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    };
    const Manufacturer = () => {
        const [logoutSuccess, setLogoutSuccess] = useState(false);
        const [currentAccount, setCurrentAccount] = useState("");
    
        useEffect(() => {
            findMetaMaskAccount().then((account) => {
                if (account !== null) {
                    setCurrentAccount(account);
                }
            });
        }, []);

        const connectWallet = async () => {
            try {
                const ethereum = getEthereumObject();
                if (!ethereum) {
                    alert("Get MetaMask!");
                    return;
                }
    
                const accounts = await ethereum.request({
                    method: "eth_requestAccounts",
                });
    
                console.log("Connected", accounts[0]);
                setCurrentAccount(accounts[0]);
            } catch (error) {
                console.error(error);
            }
        };
    
        const handleLogout = () => {
            // Show logout success message
            setLogoutSuccess(true);
    
            // Delay the logout action for 2 seconds
            setTimeout(() => {
                // Perform logout action here
                // For example, navigate to the login page
                window.location.href = '/login';
            }, 2000);
        };
    
        const handleLogoutSuccessClose = () => {
            setLogoutSuccess(false);
        };



    return (
        <div className="admin-container">
            <div className="admin-content">
                <Box
                    sx={{                        
                        position: 'absolute',
                        top: 20,
                        right: 20,
                    }}
                >
                    <Btn onClick={handleLogout} endIcon={<LogoutIcon />}>Logout</Btn>                    
                </Box>
                <div className="admin-text">
                    <h2>Welcome:</h2>
                    <h1>MANUFACTURER</h1>
                </div>
                <div className="admin-buttons">
                    <LinkButton to="/profile" className="admin-btn" buttonStyle='btn--long' buttonSize='btn--large'>Check Profile</LinkButton>
                    <LinkButton to="/add-product" className="admin-btn" buttonStyle='btn--long' buttonSize='btn--large'>Add Product</LinkButton>
                    <LinkButton to="/check-product" className="admin-btn" buttonStyle='btn--long' buttonSize='btn--large'>Check Added Products</LinkButton>
                    {!currentAccount && (
                    <Button className="btns" buttonStyle='btn--long' buttonSize='btn--large' onClick={connectWallet}>Connect Wallet</Button>
                    )}
                </div>
            </div>

            {/* Logout success Snackbar */}
            <Snackbar
                open={logoutSuccess}
                autoHideDuration={3000} // Set auto hide duration to 3 seconds
                onClose={handleLogoutSuccessClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} // Position Snackbar at the bottom right
                sx={{
                    '& .MuiSnackbarContent-root': {
                        minWidth: '400px', // Set the minimum width of the Snackbar
                        minHeight: '70px', // Set the minimum height of the Snackbar
                    },
                }}
            >
                <Alert onClose={handleLogoutSuccessClose} severity="success">
                    Logout successful!
                </Alert>
            </Snackbar>
        </div>
    );
}

export default Manufacturer;
