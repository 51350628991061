import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./header.css";

const Navbar = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const isAboutUsPage = location.pathname === "/aboutus";
  const isLoginPage = location.pathname === "/login";
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  // Add console.log statements to debug event handlers
  const handleNavLinkClick = () => {
    console.log("NavLink clicked");
    closeMenu(); // Close menu when NavLink is clicked
  };

  return (
    <nav className="navbar">
      <h2 className="title">Authenticheck</h2>
      <div className="bars" onClick={toggleMenu}>&#9776;</div>
      <div className={`nav-menu ${menuOpen ? 'active' : ''}`}>
      {!isAboutUsPage && !isLoginPage && (
          <NavLink to="/myproducts" className="nav-button" activeClassName="active" onClick={handleNavLinkClick}>
            <span style={{ fontWeight: 'bold', color: 'white' }}>My Products</span>
          </NavLink>
        )}
        {!isHomePage && (
          <NavLink to="/" className="nav-button" activeClassName="active" onClick={handleNavLinkClick}>
            <span  style={{ fontWeight: 'bold', color: 'white' }}>Home</span>
          </NavLink>
        )}
        {!isAboutUsPage && (
          <NavLink to="/aboutus" className="nav-button" activeClassName="active" onClick={handleNavLinkClick}>
            <span style={{ fontWeight: 'bold', color: 'white' }}>About Us</span>
          </NavLink>
        )}
        {/* {!isAboutUsPage && !isLoginPage && ( */}
          <NavLink to="/scanner" className="nav-button" activeClassName="active" onClick={handleNavLinkClick}>
            <span style={{ fontWeight: 'bold', color: 'white' }}>Scanner</span>
          </NavLink>
        {/* )} */}
        {!isLoginPage && (
          <NavLink to="/login" className="nav-button" activeClassName="active" onClick={handleNavLinkClick}>
            <span style={{ fontWeight: 'bold', color: 'white' }}>Login</span>
          </NavLink>
        )} 
      </div>
    </nav>
  );
};

export default Navbar;