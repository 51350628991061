import React, { useState } from 'react';
import { LinkButton } from '../LinkButton';
import { Box, Button as Btn, Snackbar, Alert } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import '../Admin.css'; // Import CSS file for styling

const Admin = () => {
    const [logoutSuccess, setLogoutSuccess] = useState(false);

    const handleLogout = () => {
        // Show logout success message
        setLogoutSuccess(true);

        // Delay the logout action for 2 seconds
        setTimeout(() => {
            // Perform logout action here
            // For example, navigate to the login page
            window.location.href = '/login';
        }, 2000);
    };

    const handleLogoutSuccessClose = () => {
        setLogoutSuccess(false);
    };

    return (
        <div className="admin-container">
            <div className="admin-content">
                <Box
                    sx={{                        
                        position: 'absolute',
                        top: 20,
                        right: 20,
                    }}
                >
                    <Btn onClick={handleLogout} endIcon={<LogoutIcon />}>Logout</Btn>                    
                </Box>
                <div className="admin-text">
                    <h2>Welcome:</h2>
                    <h1>ADMIN</h1>
                </div>
                <div className="admin-buttons">
                    <LinkButton to="/add-account" className="admin-btn" buttonStyle='btn--long' buttonSize='btn--large'>Add Account</LinkButton>
                    <LinkButton to="/manage-account" className="admin-btn" buttonStyle='btn--long' buttonSize='btn--large'>Manage Accounts</LinkButton>
                </div>
            </div>

            {/* Logout success Snackbar */}
            <Snackbar
                open={logoutSuccess}
                autoHideDuration={3000} // Set auto hide duration to 3 seconds
                onClose={handleLogoutSuccessClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} // Position Snackbar at the bottom right
                sx={{
                    '& .MuiSnackbarContent-root': {
                        minWidth: '400px', // Set the minimum width of the Snackbar
                        minHeight: '70px', // Set the minimum height of the Snackbar
                    },
                }}
            >
                <Alert onClose={handleLogoutSuccessClose} severity="success">
                    Logout successful!
                </Alert>
            </Snackbar>
        </div>
    );
}

export default Admin;
