import Header from "../Header";
import IconsDiv from "../icons-div";
import "../about-us-page.css";

const AboutUsPage = () => {
  return (
    <div className="aboutus-page">
      <Header />
      <section className="aboutinfo-div">
        <div className="aboutus-text">
          <div className="text">
            <h1 className="about-us">ABOUT US</h1>
            <h3 className="authenticheck-utilizes-the-container">
              <p className="authenticheck-utilizes-the">{`AuthentiCheck utilizes the immutable nature of the blockchain to create a decentralized database where the information and transaction records of the product are securely stored. Through our platform, users can easily verify the authenticity of their purchases by accessing detailed product information. `}</p>
              <p className="we-envision-a">
                We envision a future where counterfeit products are a thing of
                the past, and consumers can shop with confidence, knowing that
                every purchase they make is genuine. By fostering trust and
                transparency in the marketplace, we aim to level the playing
                field for both consumers and legitimate brands, ultimately
                creating a safer and more trustworthy shopping experience for
                all.
              </p>
            </h3>
          </div>
        </div>
      </section>
      <IconsDiv />
    </div>
  );
};

export default AboutUsPage;
