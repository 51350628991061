import React from 'react';
import { Box, Paper, Typography, Button } from '@mui/material';
import bgImg from '../../img/bg.png';
import { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import jsQR from 'jsqr';
import QrScanner from "../QrScanner";
import MobilePhoneFrame from '../MobilePhoneFrame';

const ScannerPage = () => {
    const CONTRACT_ADDRESS = '0x2cea51a7fd389f132f720da3e5c853b0e46120c1';
    const [qrData, setQrData] = useState('');

    const { auth } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (qrData) {
            const arr = qrData.split(",");
            const contractAddress = arr[0];

            if (contractAddress) {
                if (contractAddress === CONTRACT_ADDRESS) {
                    if (auth.role === "supplier") {
                        navRole();
                    } else {
                        navUser();
                    }
                } else {
                    navFakeProduct();
                }
            }
        }
    }, [qrData, auth]);

    const passData = (data) => {
        setQrData(data);
    };

    const navRole = () => {
        navigate('/update-product', { state: { qrData } });
    }

    const navUser = () => {
        navigate('/authentic-product', { state: { qrData } });
    }

    const navFakeProduct = () => {
        navigate('/fake-product');
    }

    const handleBack = () => {
        navigate(-1);
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            const image = new Image();
            image.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = image.width;
                canvas.height = image.height;
                ctx.drawImage(image, 0, 0);
                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                const code = jsQR(imageData.data, imageData.width, imageData.height);
                if (code) {
                    passData(code.data);
                } else {
                    alert('No QR code found in the image.');
                }
            };
            image.src = reader.result;
        };
        reader.readAsDataURL(file);
    };

    return (
        <Box
            sx={{
                backgroundImage: `url(${bgImg})`,
                minHeight: "80vh",
                backgroundRepeat: "no-repeat",
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundSize: 'cover',
                zIndex: -2,
                overflowY: "scroll",
                transition: "opacity 0.5s ease-in-out",
                opacity: 1,
            }}
        >
            <Paper elevation={3} sx={{
                width: "400px",
                height: "600px",
                margin: "auto",
                marginTop: "10%",
                marginBottom: "10%",
                padding: "3%",
                backgroundColor: "#e3eefc",
                transition: "transform 0.3s ease-in-out",
                transform: "scale(1)",
            }}>
                <MobilePhoneFrame handleBack={handleBack}>
                    <Box sx={{
                        textAlign: "center",
                        marginBottom: "5%",
                        transition: "opacity 0.5s ease-in-out",
                        opacity: 1,
                    }}>
                        <Typography
                            variant="h2"
                            sx={{
                                textAlign: "center",
                                marginBottom: "3%",
                                fontFamily: 'Gambetta',
                                fontWeight: "bold",
                                fontSize: "2.5rem",
                                transition: "color 0.3s ease-in-out",
                                color: "#000",
                            }}
                        >
                            <QrScanner passData={passData} />
                            Scan QR Code
                        </Typography>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            style={{
                                transition: "opacity 0.5s ease-in-out",
                                opacity: 1,
                            }}
                        />
                    </Box>
                </MobilePhoneFrame>
            </Paper>
        </Box>
    );

}

export default ScannerPage;
