import React from 'react';
import { Box, Button } from '@mui/material';

const MobilePhoneFrame = ({ children, handleBack }) => {
    return (
        <Box
            sx={{
                position: 'relative',
                maxWidth: '450px', // Increased the max width
                width: '90%',
                margin: 'auto',
                height: '550px',
                border: '1px solid #ddd',
                borderRadius: '20px',
                backgroundColor: '#f5f5f5',
                boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                overflow: 'hidden',
                padding: '20px',
                // marginTop: '50px',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '20px',
                    backgroundColor: '#333',
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    backgroundColor: '#333',
                    zIndex: 1,
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    top: '40px',
                    left: 0,
                    right: 0,
                    textAlign: 'center',
                    fontSize: '2rem', // Adjusted font size
                    color: '#333', // Text color
                    zIndex: 1,
                }}
            >
                Check Authentication
            </Box>
            <Box
                sx={{
                    position: 'relative',
                    padding: '20px',
                }}
            >
                {children}
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '20px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                }}
            >
                <Button onClick={handleBack}>Back</Button>
            </Box>
        </Box>
    );
}

export default MobilePhoneFrame;
