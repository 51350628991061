import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardMedia, CardContent, Typography, Button } from '@material-ui/core';
import { ethers } from "ethers";
import abi from '../../utils/Identeefi.json';
import bgImg from '../../img/bg2.png';
import axios from 'axios';
import dayjs from 'dayjs';
import {  useNavigate } from 'react-router-dom';
import Geocode from "react-geocode";
import QRCode from 'qrcode.react';


const getEthereumObject = () => window.ethereum;

/*
 * This function returns the first linked account found.
 * If there is no account linked, it will return null.
 */

const findMetaMaskAccount = async () => {
    try {
        const ethereum = getEthereumObject();

        /*
        * First make sure we have access to the Ethereum object.
        */
        if (!ethereum) {
            console.error("Make sure you have Metamask!");
            return null;
        }

        console.log("We have the Ethereum object", ethereum);
        const accounts = await ethereum.request({ method: "eth_accounts" });

        if (accounts.length !== 0) {
            const account = accounts[0];
            console.log("Found an authorized account:", account);
            return account;
        } else {
            console.error("No authorized account found");
            return null;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    card: {
        maxWidth: 300,
        margin: theme.spacing(2),
    },
    media: {
        height: 200,
    },
}));

const ProductBuy = () => {
    const [currentAccount, setCurrentAccount] = useState("");
    const [currDate, setCurrDate] = useState('');
    const [currLatitude, setCurrLatitude] = useState("");
    const [currLongtitude, setCurrLongtitude] = useState("");
    const [currName, setCurrName] = useState("");
    const [currLocation, setCurrLocation] = useState("");
    const [serialNumber, setSerialNumber] = useState("");
    const [loading, setLoading] = useState("");

    const CONTRACT_ADDRESS = '0x2cea51a7fd389f132f720da3e5c853b0e46120c1';
    const CONTRACT_ABI = abi.abi;

    const [name, setName] = useState("");
    const [brand, setBrand] = useState("");
    const [description, setDescription] = useState("");
    const [imageName, setImageName] = useState("");
    const classes = useStyles();
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();
    const [isSold, setIsSold] = useState(false);
    const [qrData, setQrData] = useState('');
    const [image, setImage] = useState({
        file: [],
        filepreview: null
    });

    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://backend-node-hmry.onrender.com/get-listed-products');
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching product list:', error);
            }
        };

        fetchData();
    }, []);
    const getImage = async (imageName) => {
        setImage(prevState => ({
            ...prevState,
            filepreview: `https://backend-node-hmry.onrender.com/file/product/${imageName}`
        }));
    };
    const getCurrentTimeLocation = () => {
        setCurrDate(dayjs().unix())
        navigator.geolocation.getCurrentPosition(function (position) {
            setCurrLatitude(position.coords.latitude);
            setCurrLongtitude(position.coords.longitude);
        });
    }

    const handleBack = () => {
        navigate(-1)
    }
    
    const handleSubmit = async (serialNumber, e) => {
        e.preventDefault();
        console.log("here")
        //setLoading("Please pay the transaction fee to update the product details...");
    
        // Update state with product details
        setSerialNumber(serialNumber);
        /* setName(serialNumber.name);
        setBrand(serialNumber.brand); */
        console.log(serialNumber.serialnumber)
        // Call updateProduct with serial number
        //await updateProduct(serialNumber, e);
        const serialNumberForBuyProduct = serialNumber.serialnumber;
        navUser(serialNumberForBuyProduct);
    }
    const navUser = (serialNumberForBuyProduct) => {
        navigate('/buy-product-details', { state: { serialNumberForBuyProduct }});
    }
    useEffect(() => {
        console.log("useEffect 3")

        
        getCurrentTimeLocation();
    }, []);
    useEffect(() => {
        Geocode.setApiKey('AIzaSyB8HH9EweeFukvBpKEu5wTNktd7oXjX8vE')

        Geocode.fromLatLng(currLatitude, currLongtitude).then(
            (response) => {
                const address = response.results[0].formatted_address;
                let city, state, country;
                for (let i = 0; i < response.results[0].address_components.length; i++) {
                    for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                        switch (response.results[0].address_components[i].types[j]) {
                            case "locality":
                                city = response.results[0].address_components[i].long_name;
                                break;
                            case "administrative_area_level_1":
                                state = response.results[0].address_components[i].long_name;
                                break;
                            case "country":
                                country = response.results[0].address_components[i].long_name;
                                break;
                        }
                    }
                }

                setCurrLocation(address.replace(/,/g, ';'));
                console.log("city, state, country: ", city, state, country);
                console.log("address:", address);
            },
            (error) => {
                console.error(error);
            }
        );

    }, [currLatitude, currLongtitude]);

   
    const generateQRCode = async (serialNumber) => {
        // const qrCode = await productContract.getProduct(serialNumber);
        const data = CONTRACT_ADDRESS + ',' + serialNumber.serialnumber
        setQrData(data);
        console.log("QR Code: ", qrData);

    }

    const downloadQR = () => {
        const canvas = document.getElementById("QRCode");
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${serialNumber.serialnumber}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };

    const ChangeProductStatus = async (serialNumber) => {
        try {
            console.log(serialNumber.serialnumber); // Make sure this outputs the correct serial number
            // Make a POST request to update the product status
            const res = await axios.post('https://backend-node-hmry.onrender.com/sold-products', {
                serialnumber: serialNumber.serialnumber, // Make sure property name is correct
                status: 'sold'
            }, {
                headers: { 'Content-Type': 'application/json' },
            });
    
            console.log('Product status updated:', res.data);
        } catch (err) {
            console.error('Error updating product status:', err);
        }
    }
    
    

    const updateProduct = async (serialNumber,e) => {
        e.preventDefault();

        try {
            const { ethereum } = window;

            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner();
                const productContract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);

                
                console.log(serialNumber.serialnumber, currName, currLocation, currDate.toString(), true)
                // write transactions 'Sn1001,Iphone,Apple,Test,iphone.jpg,false'
                const registerTxn = await productContract.addProductHistory(serialNumber.serialnumber, "Hassan", currLocation, currDate.toString(), true);
                console.log("Mining (Adding Product History) ...", registerTxn.hash);
                setLoading("Mining (Add Product History) ...", registerTxn.hash);

                await registerTxn.wait();
                console.log("Mined (Add Product History) --", registerTxn.hash);
                setLoading("Mined (Add Product History) --", registerTxn.hash);
                console.log(serialNumber.serialnumber)
                generateQRCode(serialNumber);
                const product = await productContract.getProduct(serialNumber.serialnumber);
                //if (!product.isSold && msg.value >= product.price) {
                await productContract.purchaseProduct(serialNumber.serialnumber);
                //}
                console.log("Retrieved product...", product);
                ChangeProductStatus(serialNumber);
                setLoading("Done! Product details updated successfully!");
                
            } else {
                console.log("Ethereum object doesn't exist!");
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div style={{ 
            background: 'linear-gradient(180deg, #0D011C, #181242)',
            minHeight: '100vh',
            padding: '20px', // Apply padding to the outermost container
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', // Ensure the div covers the entire viewport height
        }}>
            <Button 
                onClick={handleBack} 
                style={{ 
                    background: 'none', 
                    color: 'white', 
                    marginBottom: '20px', 
                    alignSelf: 'flex-start', 
                    border: 'none',
                    fontSize: '1.1rem', // Increase button font size
                    transition: 'transform 0.3s, color 0.3s', // Add transition effect
                    '&:hover': {
                        transform: 'scale(1.05)', // Increase size on hover
                        color: '#ffd700', // Change color on hover
                    }
                }} 
                color="primary" 
                variant="outlined"
            >
                Back
            </Button>
            <>
                <Typography>
                    <div style ={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <h1 style={{ color: 'white', fontFamily: 'Arial, sans-serif', fontSize: '2rem', fontWeight: 'bold', textTransform: 'uppercase', letterSpacing: '1px', textAlign: 'center', marginBottom: '40px', border: '1px solid white', padding: '5px',  backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                            Product Added
                        </h1>
                    </div>
                </Typography>
            </>
            <Grid container spacing={2} style={{ background: 'none' }}>
                {products.map((product, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card className={classes.card} style={{ width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.05)', boxShadow: 'none' }}>
                            {/* Product Image */}
                            <CardMedia
                                component="img"
                                alt={product.image}
                                height="200"
                                style={{ objectFit: 'cover', maxWidth: '200px', margin: 'auto'}}
                                image={`https://backend-node-hmry.onrender.com/file/product/${product.image}`}
                            />
                            {/* Product Details */}
                            <CardContent style={{textAlign:'center', color: 'white'}}>
                                <Typography variant="h6" x>Serial Number: {product.serialnumber}</Typography>
                                <Typography variant="body1">Name: {product.name}</Typography>
                                <Typography variant="body1">Brand: {product.brand}</Typography>
                            </CardContent>
                            {/* List Button */}
                            <CardContent style={{textAlign:'center'}}>
                                <Button variant="contained" color="primary" onClick={(e) => handleSubmit(product, e)}>
                                    Buy
                                </Button>
                                {qrData !== "" ? (
                                    <div style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}>
                                        <QRCode value={qrData} id="QRCode" />
                                    </div>
                                ) : null}
    
                                {/* Download button */}
                                {qrData !== "" && (
                                    <Button
                                        variant="outlined"
                                        component="label"
                                        fullWidth
                                        sx={{ marginTop: "3%", marginBottom: "3%" }}                            
                                        onClick={downloadQR}
                                    >
                                        Download
                                    </Button>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
    
};

export default ProductBuy;
