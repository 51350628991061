import { Box, Paper, TextField,Avatar, Typography, Button } from '@mui/material';
import bgImg from '../../img/bg.png';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import abi from '../../utils/Identeefi.json';
import { useEffect, useState } from 'react';
import { ethers } from "ethers";
import axios from 'axios';

const getEthereumObject = () => window.ethereum;

/*
 * This function returns the first linked account found.
 * If there is no account linked, it will return null.
 */
const findMetaMaskAccount = async () => {
    try {
        const ethereum = getEthereumObject();

        /*
        * First make sure we have access to the Ethereum object.
        */
        if (!ethereum) {
            console.error("Make sure you have Metamask!");
            return null;
        }

        console.log("We have the Ethereum object", ethereum);
        const accounts = await ethereum.request({ method: "eth_accounts" });

        if (accounts.length !== 0) {
            const account = accounts[0];
            console.log("Found an authorized account:", account);
            return account;
        } else {
            console.error("No authorized account found");
            return null;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
};



const ProductBuyDetails = () => {
    const [currentAccount, setCurrentAccount] = useState("");

    const [serialNumber, setSerialNumber] = useState("");
    const [productData, setProductData] = useState("");
    const [price, setPrice] = useState("");

    const [name, setName] = useState("P");
    const [brand, setBrand] = useState("");
    const [description, setDescription] = useState("");
    const [history, setHistory] = useState([]);
    const [isSold, setIsSold] = useState(false);
    const [toUpdate, setToUpdate] = useState(false);
    const [owner,setOwner]= useState("");
    const [review,setReview]= useState("");
    const [contreview,setcontreview] = useState("");
    const [image, setImage] = useState({
        file: [],
        filepreview: null
    });
    const isReviewValid = review.trim().length > 0;



    const CONTRACT_ADDRESS = '0x2cea51a7fd389f132f720da3e5c853b0e46120c1';
    const CONTRACT_ABI = abi.abi;

    const navigate = useNavigate();
    const location = useLocation();
    const serialNumberForReview = location.state?.serialNumberForReview;

    
    useEffect(() => {
        console.log("useEffect 1");
        findMetaMaskAccount().then((account) => {
            if (account !== null) {
                setCurrentAccount(account);
            }
        });

        if (serialNumberForReview) {
            handleScan(serialNumberForReview)
        }

    }, [serialNumberForReview]);


    const getImage = async (imageName) => {
        setImage(prevState => ({
            ...prevState,
            filepreview: `https://backend-node-hmry.onrender.com/file/product/${imageName}`
            })
        )
    }

   

    const handleScan = async (serialNumberForReview) => {
        const data = serialNumberForReview;
        
        setSerialNumber(data);

        

       // if (contractAddress === CONTRACT_ADDRESS) {

            try {
                const { ethereum } = window;
            
                if (ethereum) {
                    const provider = new ethers.providers.Web3Provider(ethereum);
                    const signer = await provider.getSigner();
                    const productContract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
            
                    console.log(data);
            
                    const product = await productContract.getProduct(data);
                    console.log("Retrieved product...", product);
                    setData(product.toString());
                } else {
                    console.log("Ethereum object doesn't exist!");
                }
            } catch (error) {
                console.error("Error fetching product:", error);
            }
            
       // }
    };

    

    const setData = (d) => {
        console.log("product data: ", d);

        const arr = d.split(",");
        console.log("arr", arr)

        setName(arr[1]);
        setBrand(arr[2]);
        setDescription(arr[3].replace(/;/g, ","));
        getImage(arr[4]);
        setPrice(arr[5]);
        setOwner(arr[6]);
        setcontreview(arr[7])

        const hist = [];
        let start = 8;

        for (let i = 8; i < arr.length; i += 8) {
            const actor = arr[start + 1];
            const location = arr[start + 2].replace(/;/g, ",");
            const timestamp = arr[start + 3];
            const isSold = arr[start + 4] === "true" ? setIsSold(true) : false;
            console.log(actor, location, timestamp, isSold)
            hist.push({
                actor, location, timestamp, isSold
            });

            start += 6;
        }
        console.log("hist", hist)
        setHistory(hist);

    }

    const handleBack = () => {
        navigate(-2)
    }


    const getHistory = () => {
        return history.map((item, index) => {
            const date = dayjs(item.timestamp * 1000).format('MM/DD/YYYY');
            const time = dayjs(item.timestamp * 1000).format('HH:mm a');
            console.log("getting history")

            return (
                <TimelineItem key={index}>
                    <TimelineOppositeContent color="textSecondary">
                        {time} {date}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography>Location: {item.location}</Typography>
                        <Typography>Actor: {item.actor}</Typography>
                    </TimelineContent>
                </TimelineItem>
            );
        });
    }
    


    const SubmitReview = async () => {
        try {
            const { ethereum } = window;
    
            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner();
                const productContract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
    
                console.log("here")
      
                // Read product price
                console.log(price);
    
                // Buy product
                const registerTxn = await productContract.leaveReview(serialNumber.toString(),review);
                console.log("(Leaving Review)...", registerTxn.hash);
    
                // Wait for transaction to be mined
                await registerTxn.wait();
                console.log("Mined (Add Product History) --", registerTxn.hash);
    
                // Retrieve updated product information
                const product = await productContract.getProduct(serialNumber);
                console.log("Retrieved product...", product);
    
            } else {
                console.log("Ethereum object doesn't exist!");
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Box sx={{
            backgroundImage: `url(${bgImg})`,
            minHeight: "80vh",
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            zIndex: -2,
            overflowY: "scroll"
        }}>
            <Paper elevation={3} sx={{ width: "400px", margin: "auto", marginTop: "10%", marginBottom: "10%", padding: "3%", backgroundColor: "#e3eefc" }}>

                <Typography
                    variant="body2"
                    sx={{
                        textAlign: "center", marginTop: "3%"
                    }}
                >

                    Your Product is Authentic!</Typography>
                <Box
                    sx={{
                        textAlign: "center", marginBottom: "5%",
                    }}
                >

                    <Typography
                        variant="h2"
                        sx={{
                            textAlign: "center", marginBottom: "3%",
                            fontFamily: 'Gambetta', fontWeight: "bold", fontSize: "2.5rem"
                        }}
                    >
                        Product Details</Typography>

                    <Box
                        sx={{
                            display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', flex: 1, width: '100%',
                            marginTop: '5%', marginBottom: '5%'
                        }}
                    >
                        <Box
                            sx={{
                                marginRight: '1.5%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', flex: '0 0 35%', width: '35%'
                            }}
                        >
                            <Avatar
                                alt={name}
                                src={image.filepreview}
                                sx={{
                                    width: 100,
                                    height: 100,
                                    margin: "auto",
                                    marginBottom: "3%",
                                    backgroundColor: "#3f51b5"
                                }}
                            >
                                {name}


                            </Avatar>

                        </Box>
                        <Box
                            sx={{
                                marginLeft: '1.5%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'left', flex: '0 0 65%', width: '65%'
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    textAlign: "left", marginBottom: "5%",
                                }}
                            >
                                {name}

                            </Typography>

                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: "left", marginBottom: "3%",
                                }}
                            >
                                Serial Number: {serialNumber}
                            </Typography>


                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: "left", marginBottom: "3%",
                                }}
                            >
                                Description: {description}
                            </Typography>

                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: "left", marginBottom: "3%",
                                }}
                            >
                                Brand: {brand}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: "left", marginBottom: "3%",
                                }}
                            >
                                Price: {price / 1000000000000000000} ETH
                            </Typography>
                            {/* <Typography
                                variant="body2"
                                sx={{
                                    textAlign: "left", marginBottom: "3%",
                                }}
                            >
                                Review: {contreview}
                            </Typography> */}


                        </Box>

                    </Box>

                    <Timeline
                        sx={{
                            [`& .${timelineOppositeContentClasses.root}`]: {
                                flex: 0.2,
                            },
                        }}
                    >
                        {getHistory()}
                        <TimelineItem>
                            <TimelineOppositeContent color="textSecondary">
                                {dayjs().format('HH:mm a')} {dayjs().format('MM/DD/YYYY')}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography>IsSold: {isSold.toString()}</Typography>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>

                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center"
                        }}
                    >
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            margin="normal"
                            label="Write Review"
                            variant="outlined"
                            onChange={(e) => setReview(e.target.value)} // Update the review text
                            value={review}
                            required
                            multiline
                            rows={4}
                            sx={{
                              textAlign: 'center',
                            }}
                        />

                        <Button
                          variant="contained"
                          type="submit"
                          sx={{
                            width: '100%',
                            marginTop: '3%',
                            backgroundColor: '#98b5d5',
                            '&:hover': { backgroundColor: '#618dbd' },
                          }}
                          onClick={() => {
                            if (isReviewValid) {
                              SubmitReview(review); // Call the submit function with the review text
                            } else {
                              alert('Please enter your review before submitting.'); // Provide feedback if empty
                            }
                          }}
                          disabled={!isReviewValid} // Disable the button if the review is empty
                        >
                          Submit Review
                        </Button>

                        </Box>

                        <Button
                            onClick={handleBack}
                            sx={{
                                marginTop: "5%",
                            }}
                        >
                            Back
                        </Button>
                </Box>
            </Paper>
        </Box>
    )
}

export default ProductBuyDetails;