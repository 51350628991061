import { Box, Paper, Typography, IconButton, TextField } from '@mui/material';
import axios from 'axios';
import { useState, useEffect } from 'react';
import bgImg from '../../img/ma.png';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ManageAccount = () => {
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        handleData();
    }, []);

    const handleData = async () => {
        try {
            const res = await axios.get('https://backend-node-hmry.onrender.com/profileAll');
            setRows(res.data);
            setFilteredRows(res.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleDelete = async (id) => {
        try {
            console.log("Deleting row with ID:", id);
            await axios.delete(`https://backend-node-hmry.onrender.com/profile/delete/${id}`);
            setRows(rows.filter((row) => row.id !== id));
            setFilteredRows(filteredRows.filter((row) => row.id !== id));
        } catch (error) {
            console.error("Error deleting row:", error);
        }
    };

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchTerm(searchTerm);
        const filtered = rows.filter(
            (row) =>
                row.name.toLowerCase().includes(searchTerm) ||
                row.description.toLowerCase().includes(searchTerm) ||
                row.username.toLowerCase().includes(searchTerm) ||
                row.website.toLowerCase().includes(searchTerm) ||
                row.location.toLowerCase().includes(searchTerm) ||
                row.role.toLowerCase().includes(searchTerm)
        );
        setFilteredRows(filtered);
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <Box
            sx={{
                backgroundImage: `url(${bgImg})`,// Background image from Unsplash
                backgroundSize: 'cover',
                backgroundPosition: 'center', // Adjust background position
                backgroundAttachment: 'fixed',
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px',
                color: '#fff', // White text color
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    width: '90%',
                    padding: '20px',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust background color opacity
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <IconButton onClick={handleBack} sx={{ mr: 1, color: 'black' }}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h4" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
                        Manage Accounts
                    </Typography>
                </Box>
                
                <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    value={searchTerm}
                    onChange={handleSearch}
                    sx={{ marginBottom: '20px', width: '100%' }}
                    InputProps={{
                        style: { color: 'black' }, 
                    }}
                />

                <Paper
                    sx={{
                        width: '100%',
                        overflowX: 'auto',
                        marginBottom: '20px',
                    }}
                >
                    <table style={{ width: '100%', color: 'black', backgroundColor:'white', fontSize: '22px', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th style={{ textAlign: 'left', padding: '8px' }}>Name</th>
                                <th style={{ textAlign: 'left', padding: '8px' }}>Description</th>
                                <th style={{ textAlign: 'left', padding: '8px' }}>Username</th>
                                <th style={{ textAlign: 'left', padding: '8px' }}>Website</th>
                                <th style={{ textAlign: 'left', padding: '8px' }}>Location</th>
                                <th style={{ textAlign: 'left', padding: '8px' }}>Role</th>
                                <th style={{ textAlign: 'left', padding: '8px' }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredRows.map((row) => (
                                <tr key={row.id}>
                                    <td style={{ padding: '8px' }}>{row.name}</td>
                                    <td style={{ padding: '8px' }}>{row.description}</td>
                                    <td style={{ padding: '8px' }}>{row.username}</td>
                                    <td style={{ padding: '8px' }}>{row.website}</td>
                                    <td style={{ padding: '8px' }}>{row.location}</td>
                                    <td style={{ padding: '8px' }}>{row.role}</td>
                                    <td style={{ padding: '8px' }}>
                                        <IconButton onClick={() => handleDelete(row.id)} sx={{ color: 'red' }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Paper>
            </Paper>
        </Box>
    );
}

export default ManageAccount;
