import "./icons-div.css";

const IconsDiv = () => {
  return (
    <section className="icons-div">
      <div className="main-div-icons">
        <div className="inner-div-icons">
          <div className="metamask-icon">
            <img
              className="bnb-chain-binance-smart-chain-icon"
              loading="lazy"
              alt=""
              src="/bnbchainbinancesmartchainlogo-1@2x.png"
            />
          </div>
          <div className="solodity-icon">
            <img className="icon-4" alt="" src="/2.png" />
          </div>
          <div className="etherium-icon">
            <img className="icon-41" alt="" src="/3.png" />
          </div>
          <div className="bitcoin-icon">
            <img className="icon-42" alt="" src="/icon4@2x.png" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default IconsDiv;