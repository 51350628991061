import FirstDivProduct from "./first-div-product";
import "./product-display-section.css";
import { useNavigate } from 'react-router-dom';

const ProductDisplaySection = () => {

  const navigate = useNavigate()
  const redirectToBuyProduct = () => {
  navigate('/buy-product');
};

  return (
    <div className="product-display-section">
      <div className="top-selling-products">
        <div className="text">
          <h2 className="top-selling-products-container">
            <span>{`Unique `}</span>
            <span className="products">Products</span>
          </h2>
        </div>
        <div className="products1">
          <div className="first-product-div">
            <FirstDivProduct
              image8="/image-8-5@2x.png"
              ethereum1="/ethereum@2x.png"
            />
            <FirstDivProduct
              image8="/image-8-1@2x.png"
              ethereum1="/ethereum-1@2x.png"
              propWidth="456px"
              propHeight="456px"
              firstDivProductPadding="32px"
              firstDivProductOverflow="unset"
              wrapperImage8AlignSelf="unset"
              wrapperImage8Width="372.7px"
              propBorderRadius="8px"
              image8IconAlignSelf="unset"
              image8IconOverflow="unset"
              infoPadding="unset"
              buyProduct1AlignSelf="unset"
              buyProduct1Width="372.7px"
              propPadding1="var(--padding-xs) var(--padding-21xl)"
              propDisplay="unset"
              propFlex="1"
              propTextAlign="center"
              propMinWidth="unset"
            />
            <FirstDivProduct
              image8="/image-8-2@2x.png"
              ethereum1="/ethereum-1@2x.png"
              propWidth="456px"
              propHeight="456px"
              firstDivProductPadding="var(--padding-13xl) var(--padding-11xl) var(--padding-13xl) var(--padding-13xl)"
              firstDivProductOverflow="unset"
              wrapperImage8AlignSelf="unset"
              wrapperImage8Width="372.7px"
              propBorderRadius="var(--br-11xl) var(--br-11xl) var(--br-8xl) var(--br-11xl)"
              image8IconAlignSelf="unset"
              image8IconOverflow="unset"
              infoPadding="unset"
              buyProduct1AlignSelf="unset"
              buyProduct1Width="372.7px"
              propPadding1="var(--padding-xs) var(--padding-21xl)"
              propDisplay="unset"
              propFlex="1"
              propTextAlign="center"
              propMinWidth="unset"
            />
          </div>
          <div className="second-div-product">
            <FirstDivProduct
              image8="/image-8-3@2x.png"
              ethereum1="/ethereum-3@2x.png"
              propWidth="456px"
              propHeight="456px"
              firstDivProductPadding="32px"
              firstDivProductOverflow="unset"
              wrapperImage8AlignSelf="stretch"
              wrapperImage8Width="unset"
              propBorderRadius="8px"
              image8IconAlignSelf="stretch"
              image8IconOverflow="hidden"
              infoPadding="0px var(--padding-11xs)"
              buyProduct1AlignSelf="stretch"
              buyProduct1Width="unset"
              propPadding1="var(--padding-xs) var(--padding-21xl)"
              propDisplay="unset"
              propFlex="1"
              propTextAlign="center"
              propMinWidth="unset"
            />
            <FirstDivProduct
              image8="/image-8-4@2x.png"
              ethereum1="/ethereum-4@2x.png"
              propWidth="456px"
              propHeight="456px"
              firstDivProductPadding="var(--padding-13xl) var(--padding-11xl)"
              firstDivProductOverflow="hidden"
              wrapperImage8AlignSelf="stretch"
              wrapperImage8Width="unset"
              propBorderRadius="8px"
              image8IconAlignSelf="stretch"
              image8IconOverflow="hidden"
              infoPadding="0px var(--padding-12xs)"
              buyProduct1AlignSelf="stretch"
              buyProduct1Width="unset"
              propPadding1="var(--padding-xs) var(--padding-xl)"
              propDisplay="inline-block"
              propFlex="unset"
              propTextAlign="left"
              propMinWidth="103px"
            />
            <FirstDivProduct
              image8="/image-8@2x.png"
              ethereum1="/ethereum-5@2x.png"
              propWidth="456px"
              propHeight="456px"
              firstDivProductPadding="var(--padding-13xl) var(--padding-9xl)"
              firstDivProductOverflow="unset"
              wrapperImage8AlignSelf="stretch"
              wrapperImage8Width="unset"
              propBorderRadius="8px"
              image8IconAlignSelf="stretch"
              image8IconOverflow="hidden"
              infoPadding="0px var(--padding-12xs)"
              buyProduct1AlignSelf="stretch"
              buyProduct1Width="unset"
              propPadding1="var(--padding-xs) var(--padding-21xl)"
              propDisplay="unset"
              propFlex="1"
              propTextAlign="center"
              propMinWidth="unset"
            />
          </div>
        </div>
        {/* <button className="explore-all-products">
          <a href="/buy-product" className="explore-all-products">
            <div className="explore-all-products1">Explore All Products</div>
          </a>
        </button> */}

        <button onClick={redirectToBuyProduct} className="explore-all-products">
          <div className="explore-all-products1">Explore All Products</div>
          </button>
      </div>
    </div>
  );
};

export default ProductDisplaySection;
