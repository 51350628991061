import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardMedia, CardContent, Typography, Button } from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import Geocode from "react-geocode";
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import bgImg from '../../img/bg3.png';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    card: {
        marginBottom: theme.spacing(2),
    },
}));

const CheckProducts = () => {
    const classes = useStyles();
    const [products, setProducts] = useState([]);
    const location = useLocation();
    const qrData = location.state?.qrData;
    const { auth } = useAuth();
    const navigate = useNavigate();
    const [currName, setCurrName] = useState("");

    useEffect(() => {
        getUsername();
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://backend-node-hmry.onrender.com/list-product');
            setProducts(response.data);
        } catch (error) {
            console.error('Error fetching product list:', error);
        }
    };

    const getUsername = async () => {
        try {
            const res = await axios.get(`https://backend-node-hmry.onrender.com/profile/${auth.user}`);
            setCurrName(res?.data[0]?.name);
        } catch (error) {
            console.error('Error fetching username:', error);
        }
    }

    const handleBack = () => {
        navigate(-1);
    }

    return (
        <div style={{
            background: 'linear-gradient(180deg, #0D011C, #181242)',
            minHeight: '100vh',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <Button
                onClick={handleBack}
                style={{
                    background: 'none',
                    color: 'white',
                    marginBottom: '20px',
                    alignSelf: 'flex-start',
                    border: 'none',
                    fontSize: '1.1rem',
                    transition: 'transform 0.3s, color 0.3s',
                    '&:hover': {
                        transform: 'scale(1.05)',
                        color: '#ffd700',
                    }
                }}
                color="primary"
                variant="outlined"
            >
                Back
            </Button>
            <Typography>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <h1 style={{ color: 'white', fontFamily: 'Arial, sans-serif', fontSize: '2rem', fontWeight: 'bold', textTransform: 'uppercase', letterSpacing: '1px', textAlign: 'center', marginBottom: '40px', border: '1px solid white', padding: '5px', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                        Manufacturer Store
                    </h1>
                </div>
            </Typography>
            <Grid container spacing={2} style={{ width: '100%', justifyContent: 'center' }}>
                {products.map((product, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} style={{ display: 'flex', justifyContent: 'center', padding: '0 10px' }}>
                        <Card className={classes.card} style={{ width: '100%', backgroundColor: 'transparent', boxShadow: 'none' }}>
                            <CardMedia
                                component="img"
                                style={{ objectFit: 'cover', maxWidth: '200px', margin: 'auto' }}
                                alt={product.image}
                                height="200"
                                image={`https://backend-node-hmry.onrender.com/file/product/${product.image}`}
                            />
                            <CardContent style={{ textAlign: 'center', color: 'white' }}>
                                <Typography variant="h6" style={{ fontWeight: 'bold' }}>Serial Number: {product.serialnumber}</Typography>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>Name: {product.name}</Typography>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>Brand: {product.brand}</Typography>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>Status: {product.status}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default CheckProducts;
