import Landing from './components/pages/Landing';
import AboutUsPage from './components/home/AboutUs'
import Login from './components/home/Login';
import ScannerPage from './components/pages/ScannerPage';
import Admin from './components/pages/Admin';
import Manufacturer from './components/pages/Manufacturer';
import Supplier from './components/pages/Supplier';
import {Routes, Route} from "react-router-dom";
import RequireAuth from './components/RequireAuth';
import Layout from './components/Layout';
import ListProduct from './components/pages/ListProduct';
import AddAccount from './components/pages/AddAccount';
import ManageAccount from './components/pages/ManageAccount';
import AddProduct from './components/pages/AddProduct';
import AddProductForm from './components/pages/AddProduct';
import CheckProducts from './components/pages/CheckProducts';
import Profile from './components/pages/Profile';
// import UpdateProduct from './components/pages/UpdateProduct';
import Product from './components/pages/Product';
import AuthenticProduct from './components/pages/AuthenticProduct';
import MyProducts from './components/pages/MyProducts';
import FakeProduct from './components/pages/FakeProduct';
// import UpdateProductDetails from './components/pages/UpdateProductDetails';
import ListProductDetails from './components/pages/ListProductDetails';
import ProductBuy from './components/pages/ProductBuy';
import ProductBuyDetails from './components/pages/ProductBuyDetails';
import MyProductGiveReview from './components/pages/MyProductGiveReview';

function App() {


  return (

    <Routes>
      <Route path='/' element={<Layout />}>
        {/* public routes */}
        <Route exact path='/' element={< Landing />}></Route>
        <Route exact path='/login' element={< Login />}></Route>
        <Route exact path='/scanner' element={< ScannerPage />}></Route>
        <Route exact path='/product' element={< Product />}></Route>
        <Route exact path='/aboutus' element={< AboutUsPage />}></Route>
        <Route exact path='/authentic-product' element={< AuthenticProduct />}></Route>
        <Route exact path='/product-buy' element={< ProductBuy />}></Route>
        <Route exact path='/fake-product' element={< FakeProduct />}></Route>
       
      
        <Route exact path='/myproducts' element={< MyProducts />}></Route>
        <Route exact path='/buy-product' element={< ProductBuy />}></Route>
        <Route exact path='/buy-product-details' element={< ProductBuyDetails />}></Route>
        <Route exact path='/my-products-give-review' element={< MyProductGiveReview />}></Route>   
        {/* <Route exact path='/check-review' element={< CheckReview />}></Route>    */}

        <Route element={<RequireAuth allowedRoles={["admin"]} />}>
          <Route exact path='/admin' element={< Admin />}></Route>
          <Route exact path='/add-account' element={< AddAccount />}></Route>
          <Route exact path='/manage-account' element={< ManageAccount />}></Route> 

        </Route>

        <Route element={<RequireAuth allowedRoles={["manufacturer", "supplier"]} />}>
          <Route exact path='/profile' element={< Profile />}></Route>
          {/* <Route exact path='/update-product' element={< UpdateProduct />}></Route> */}
          {/* <Route exact path='/update-product-details' element={< UpdateProductDetails />}></Route> */}
        </Route>

        {/* <Route element={<RequireAuth allowedRoles={["supplier", "retailer"]} />}>
        </Route> */}

        <Route element={<RequireAuth allowedRoles={["manufacturer"]} />}>
          <Route exact path='/manufacturer' element={< Manufacturer />}></Route>
          <Route exact path='/add-product' element={< AddProduct />}></Route>
          <Route exact path='/add-product-form' element={< AddProductForm />}></Route>
          <Route exact path='/check-product' element={< CheckProducts />}></Route>
        </Route>

        <Route element={<RequireAuth allowedRoles={["supplier"]} />}>
          <Route exact path='/supplier' element={< Supplier />}></Route>
          <Route exact path='/list-product' element={< ListProduct />}></Route>
          <Route exact path='/list-product-details' element={< ListProductDetails />}></Route>
          {/* <Route exact path='/update-product' element={< UpdateProduct />}></Route> */}
          {/* <Route exact path='/update-product-details' element={< UpdateProductDetails />}></Route> */}
        </Route>

        {/* <Route element={<RequireAuth allowedRoles={["retailer"]} />}>
          <Route exact path='/retailer' element={< Retailer />}></Route>
        </Route> */}

        {/* catch all */}
        {/* <Route path='*' element={< Missing />}></Route> */}

      </Route>
    </Routes>

  );
}

export default App;