import { Box, Paper, Typography, Autocomplete } from '@mui/material';
import bgImg from '../../img/bg.png';
import { TextField, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { ethers } from "ethers";
import axios from 'axios';
import Geocode from "react-geocode";
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import abi from '../../utils/Identeefi.json';

const options = ["true", "false"]

const getEthereumObject = () => window.ethereum;

/*
 * This function returns the first linked account found.
 * If there is no account linked, it will return null.
 */
const findMetaMaskAccount = async () => {
    try {
        const ethereum = getEthereumObject();

        /*
        * First make sure we have access to the Ethereum object.
        */
        if (!ethereum) {
            console.error("Make sure you have Metamask!");
            return null;
        }

        console.log("We have the Ethereum object", ethereum);
        const accounts = await ethereum.request({ method: "eth_accounts" });

        if (accounts.length !== 0) {
            const account = accounts[0];
            console.log("Found an authorized account:", account);
            return account;
        } else {
            console.error("No authorized account found");
            return null;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
};

const ListProductDetails = () => {

    const [currentAccount, setCurrentAccount] = useState("");
    const [currDate, setCurrDate] = useState('');
    const [price, setPrice] = useState('');
    const [validationAttempt, setValidationAttempt] = useState(false);
    const [currLatitude, setCurrLatitude] = useState("");
    const [currLongtitude, setCurrLongtitude] = useState("");
    const [currName, setCurrName] = useState("");
    const [currLocation, setCurrLocation] = useState("");
    const [serialNumber, setSerialNumber] = useState("");
    const [isSold, setIsSold] = useState(false);
    const [loading, setLoading] = useState("");
    

    const CONTRACT_ADDRESS = '0x2cea51a7fd389f132f720da3e5c853b0e46120c1';
    const CONTRACT_ABI = abi.abi;

    const { auth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const serialNumberForListng = location.state?.serialNumberForListng;


    useEffect(() => {
        console.log("qrdata", serialNumberForListng)
        const data = serialNumberForListng;
        // const contractAddress = data[0];
        setSerialNumber(data);
        console.log("serialNumber", serialNumber)

        findMetaMaskAccount().then((account) => {
            if (account !== null) {
                setCurrentAccount(account);
            }
        });
    });

    useEffect(() => {
        console.log("useEffect 3")

        getUsername();
        getCurrentTimeLocation();
    }, []);


    useEffect(() => {
        Geocode.setApiKey('AIzaSyB8HH9EweeFukvBpKEu5wTNktd7oXjX8vE')

        Geocode.fromLatLng(currLatitude, currLongtitude).then(
            (response) => {
                const address = response.results[0].formatted_address;
                let city, state, country;
                for (let i = 0; i < response.results[0].address_components.length; i++) {
                    for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                        switch (response.results[0].address_components[i].types[j]) {
                            case "locality":
                                city = response.results[0].address_components[i].long_name;
                                break;
                            case "administrative_area_level_1":
                                state = response.results[0].address_components[i].long_name;
                                break;
                            case "country":
                                country = response.results[0].address_components[i].long_name;
                                break;
                        }
                    }
                }

                setCurrLocation(address.replace(/,/g, ';'));
                console.log("city, state, country: ", city, state, country);
                console.log("address:", address);
            },
            (error) => {
                console.error(error);
            }
        );

    }, [currLatitude, currLongtitude]);

    const getCurrentTimeLocation = () => {
        setCurrDate(dayjs().unix())
        navigator.geolocation.getCurrentPosition(function (position) {
            setCurrLatitude(position.coords.latitude);
            setCurrLongtitude(position.coords.longitude);
        });
    }

       

    const getUsername = async (e) => {
        const res = await axios.get(`https://backend-node-hmry.onrender.com/profile/${auth.user}`)
            .then(res => {
                console.log(JSON.stringify(res?.data[0]));
                setCurrName(res?.data[0].name);

            })
    }

    const updateProduct = async (e) => {
        try {
            const { ethereum } = window;

            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner();
                const productContract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);

                console.log("here")

                // write transactions
                const registerTxn = await productContract.addProductHistory(serialNumber, currName, currLocation, currDate.toString(), Boolean(isSold), ethers.utils.parseEther(price));
                console.log("Mining (Adding Product History) ...", registerTxn.hash);
                setLoading("Mining (Add Product History) ...", registerTxn.hash);

                await registerTxn.wait();
                console.log("Mined (Add Product History) --", registerTxn.hash);
                setLoading("Mined (Add Product History) --", registerTxn.hash);

                console.log("Transaction completed", registerTxn.hash);
                const product = await productContract.getProduct(serialNumber);

                console.log("Retrieved product...", product);
                setLoading("Done! Product details updated successfully!");

            } else {
                throw new Error("Ethereum object doesn't exist");
            }
        } catch (error) {
            throw error;
        }
    };
    const ChangeProductStatus = async (serialNumber) => {
        try {
            // Make a POST request to update the product status
            console.log('Updating product status:', serialNumber);
            const res = await axios.post('https://backend-node-hmry.onrender.com/change-product-status', {
                serialNumber: serialNumber,
                status: 'listed'
            }, {
                headers: { 'Content-Type': 'application/json' },
            });
    
            console.log('Product status updated:', res.data);
        } catch (err) {
            console.error('Error updating product status:', err);
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setValidationAttempt(true);
        if (!price) { // Validate if price has a value
            return; // Exit without proceeding further
        }
        console.log('Form submitted');
        setLoading("Please pay the transaction fee to update the product details...")
        let transactionSuccessful = false; // Flag to determine if the transaction was successful

         try {
           await updateProduct(e); // Call to updateProduct, awaiting completion
           transactionSuccessful = true; // If updateProduct completes without error, set to true
         } catch (error) {
           console.error("Error during product update:", error);
           
         }
    
        if (transactionSuccessful) {
           console.log("Transaction successful, changing product status");
           ChangeProductStatus(serialNumber); // Call only if the transaction was successful
         } else {
           console.log("Transaction failed, not changing product status");
         }
    
        setLoading(""); // Reset the loading message

    }

    const handleBack = () => {
        navigate(-1)
    }


    return (
        <Box sx={{
            backgroundImage: `url(${bgImg})`,
            minHeight: "80vh",
            backgroundRepeat: "no-repeat",
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundSize: 'cover',
            zIndex: -2,
            overflowY: "scroll"
        }}>

            <Paper elevation={3} sx={{ width: "400px", margin: "auto", marginTop: "10%", marginBottom: "10%", padding: "3%", backgroundColor: "#e3eefc" }}>

                <Typography
                    variant="h2"
                    sx={{
                        textAlign: "center", marginBottom: "3%",
                        fontFamily: 'Gambetta', fontWeight: "bold", fontSize: "2.5rem"
                    }}
                >
                    Update Product Details</Typography>

                    <TextField
                        fullWidth
                        id="outlined-disabled"
                        margin="normal"
                        label="Serial Number"
                        disabled
                        onChange={(e) => setSerialNumber(e.target.value)}
                        value={serialNumber}
                        required
                    />
                    <TextField
                        fullWidth
                        error={validationAttempt && !price} // Show error if validation is attempted and price is empty
                        helperText={validationAttempt && !price ? "Price cannot be empty" : ""} // Error message
                        margin="normal"
                        label="Price"
                        type='number'
                        placeholder="Enter price in ETH (e.g., 0.5)"
                        InputLabelProps={{ style: { fontSize: '18px' } }}
                        InputProps={{ style: { fontSize: '20px' } }}
                        required
                        onChange={(e) => setPrice(e.target.value)}
                        value={price}
                    />
                    

                    {/* {auth.role === "supplier" ? null
                        : <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={options}
                            fullWidth
                            value={isSold}
                            onChange={(event, newVal) => {
                                setIsSold(newVal);
                            }}
                            renderInput={(params) =>
                                <TextField {...params}
                                    fullWidth
                                    id="outlined-basic"
                                    margin="normal"
                                    label="Is Sold?"
                                    variant="outlined"
                                    inherit="False"
                                    required
                                />}
                        />
                    } */}
                {loading === "" ? null
                        : <Typography
                            variant="body2"
                            sx={{
                                textAlign: "center", marginTop: "3%"
                            }}
                        >
                            {loading}
                        </Typography>
                    }
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Button
                      variant="contained"
                      onClick={handleSubmit} // Ensure it's bound to the specific event
                      sx={{ textAlign: 'center', width: '50%', marginTop: '3%' }}
                    >
                      Update Product
                    </Button>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Button
                        onClick={handleBack}
                        sx={{
                            marginTop: "5%",
                        }}
                    >
                        Back
                    </Button>
                </Box>
            </Paper>
        </Box>
    )
}

export default ListProductDetails;