import "./info.css";

const Info = () => {
  return (
    <div className="info1">
      <div className="procedure">
        <div className="text1">
          <button className="how-to-buy-parent">
            <div className="procedure1">Procedure</div>
          </button>
          <h2 className="how-to-buy-container">
            <p className="how-to-buy-authenticate">
              <span className="how-to">{`How to `}</span>
              <span className="buy">Buy</span>
              <span className="span">{` `}</span>
              <span className="authenticate">{`& Authenticate`}</span>
              <span>{` `}</span>
            </p>
            <p className="products-here">Products Here.</p>
          </h2>
        </div>
        <div className="three-points">
          <div className="top-div">
            <div className="third-div">
              <img className="subtract-icon" alt="" src="/subtract-2.svg" />
              <img className="sell-icon" alt="" src="/sell@2x.png" />
              <div className="info2">
                <div className="sell-your-product">Sell Your Product</div>
                <div className="you-can-sell">
                  You can sell your product with fixed-price listings.
                </div>
              </div>
            </div>
            <div className="first-div">
              <img
                className="subtract-icon1"
                rows={11}
                cols={31}
                alt=""
                src="/subtract-2.svg"
              />
              <img
                className="coin-wallet-icon"
                loading="lazy"
                alt=""
                src="/coin-wallet@2x.png"
              />
              <div className="info3">
                <div className="set-up-your">Set Up Your Wallet</div>
                <div className="connect-your-wallet">
                  Connect your wallet with metamask by clicking the wallet icon.
                </div>
              </div>
            </div>
            <div className="second-div">
              <img className="subtract-icon2" alt="" src="/subtract-1.svg" />
              <img
                className="add-new-icon"
                loading="lazy"
                alt=""
                src="/add-new@2x.png"
              />
              <div className="info4">
                <div className="create-unique-collection">
                  Create Unique Collection
                </div>
                <div className="list-the-products">
                  List the products just by giving simple descriptions, images
                  ,etc.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
