import "./top-section.css";

const TopSection = () => {
  return (
    <section className="top-section">
      <div className="main-div-top-section">
        <div className="descritption">
          <img
            className="image-6-icon"
            loading="lazy"
            alt=""
            src="/image-6@2x.png"
          />
          <div className="authenticheck-description-icon">
            <h2>Buy Unique & Authentic Products</h2>
          <div className="overview">
            <div className="heading">
              <h3 className="authenticheck-marketplace-over">
                Authenticheck Marketplace Overview
              </h3>
              </div>
              <div className="worldarts-div">
              Secure, Transparent, Decentralized: Welcome to the AuthentiCheck
              </div>
              <div className="worldarts-div">
              Unlocking Potential, Securing Futures: Your Journey with Blockchain Starts Here.
              </div>
              <div className="digital-artist-div">
              Trust in Every Transaction.
              </div>
            </div>
            <div className="details">
              <div className="worldarts-div">
                {/* <div className="world-arts">World Arts</div>
                <div className="div">30.000 +</div> */}
              </div>
              <div className="digital-artist-div">
                {/* <div className="digital-artists">Digital Artists</div>
                <div className="div1">17.000 +</div> */}
              </div>
              <div className="live-auction-div">
                {/* <div className="live-auctions">Live Auctions</div>
                <div className="div2">22.000 +</div> */}
              </div>
              <div className="unique-products-div">
                {/* <div className="unique-products">Unique Products</div>
                <div className="div3">50.000 +</div> */}
              </div>
            </div>
          </div>
        </div>
        <img className="image-icon" loading="lazy" alt="" src="/image@2x.png" />
      </div>
    </section>
  );
};

export default TopSection;
