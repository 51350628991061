import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Header from "../Header";
import TopSection from "../top-section";
import ProductDisplaySection from "../product-display-section";
import Info from "../info";
import "./landing.css";

// Global flag to track if the Botpress scripts have been added
let botpressScriptsAdded = false;

const Landing = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Get the vertical scroll position
      const scrollPosition = window.scrollY || window.pageYOffset;

      // Determine when to show the ProductDisplaySection based on scroll position
      if (scrollPosition > 500) {
        setIsVisible(true);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Check if Botpress scripts have been added
    if (!botpressScriptsAdded) {
      // Load Botpress webchat script
      const script1 = document.createElement('script');
      script1.src = "https://cdn.botpress.cloud/webchat/v1/inject.js";
      script1.async = true;
      script1.onload = () => {
        console.log("Botpress script loaded"); // Log when the script is loaded
      };

      document.head.appendChild(script1);

      // Load Botpress config script
      const script2 = document.createElement('script');
      script2.textContent = `
        window.botpressWebChat.init({
          "composerPlaceholder": "The bot helps answer your queries.",
          "botConversationDescription": "AuthentiCheck",
          "botId": "14b047a3-ed17-4f8e-b598-153494835268",
          "hostUrl": "https://cdn.botpress.cloud/webchat/v1",
          "messagingUrl": "https://messaging.botpress.cloud",
          "clientId": "14b047a3-ed17-4f8e-b598-153494835268",
          "webhookId": "3c093a94-6252-4e55-be02-9069d21f2b0d",
          "lazySocket": true,
          "themeName": "prism",
          "botName": "Support Chat",
          "avatarUrl": "https://www2.deloitte.com/content/dam/Deloitte/us/Images/promo_images/abstract/us-bda-blockchain-to-drive-supply-chain-promo.jpg",
          "stylesheet": "https://webchat-styler-css.botpress.app/prod/9c703881-e38a-4eaa-89d9-6d50154b5148/v5862/style.css",
          "frontendVersion": "v1",
          "useSessionStorage": true,
          "enableConversationDeletion": true,
          "theme": "prism",
          "themeColor": "#2563eb"
        });
      `;
      
      // Ensure the config script is added after the first script
      script1.onload = () => {
        document.head.appendChild(script2);
      };

      botpressScriptsAdded = true; // Set the flag to true after loading the scripts
    }

    // No need for cleanup
  }, []); // Empty dependency array ensures this useEffect runs once

  return (
    <div className="landing">
      <Header />
      <TopSection />
      <motion.div
        className="main-div"
        initial={{ opacity: 0, y: 100 }} // Initial state: hidden and moved down
        animate={{ opacity: 1, y: isVisible ? 0 : 100 }} // Animation: fade in and move up when isVisible is true
        transition={{ duration: 2.8 }} // Animation duration
      >
        <ProductDisplaySection />
      </motion.div>
      <motion.div
        className="landing"
        initial={{ opacity: 0 }} // Initial state: hidden
        animate={{ opacity: isVisible ? 1 : 0 }} // Animation: fade in when isVisible is true
        transition={{ duration: 0.8 }} // Animation duration
      />
      <Info />
    </div>
  );
};

export default Landing;
