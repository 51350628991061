import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Typography, IconButton, InputAdornment, Paper } from '@material-ui/core';
import { Visibility, VisibilityOff, Close as CloseIcon } from '@mui/icons-material';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import Header from '../Header';

const LOGIN_URL = '/auth';

const Login = () => {
    const { setAuth } = useAuth();
    const navigate = useNavigate();

    const errRef = useRef();
    const [popupMsg, setPopupMsg] = useState('');
    const [popupMsg2, setPopupMsg2] = useState('');
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [showPwd, setShowPwd] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd]);

    const handleBack = () => {
        navigate('/');
    }

    const handleTogglePasswordVisibility = () => {
        setShowPwd(!showPwd);
    };

    const handlePopupClose = () => {
        setPopupMsg('');
        setPopupMsg2('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const res = await axios.post(`${LOGIN_URL}/${user}/${pwd}`, {
                headers: { 'Content-Type': 'application/json' },
            });

            if (res?.data.length === 0) {
                setTimeout(() => {
                setPopupMsg2('Login Failed. Please try again later.');
            }, 500); 
            } else {
                const role = res?.data[0].role;
                setPopupMsg('Logged in successfully!');
                setAuth({ user, pwd, role })
                setUser('');
                setPwd('');
                setTimeout(() => {
                navigate(`/${role}`, { replace: true });
            }, 500); 
            }
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Server is down. Please try again later.');
            } else if (err.response?.status === 400) {
                setErrMsg('Invalid username or password.');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized access.');
            } else {
                setErrMsg('Login Failed. Please try again later.');
            }
            errRef.current.focus();
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', background: 'linear-gradient(180deg, #181242, #0D011C)' }}>
            <Header />
            <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Paper elevation={6} style={{ padding: '30px', borderRadius: '10px', background: 'white' }}>
                    <Typography variant="h1" style={{ textAlign: 'center', marginBottom: '20px', color: '#333', fontSize: '40px' }}>Welcome to Authenticheck</Typography>
                    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                        {errMsg && <Typography component="h1" variant="body2" color="error" ref={errRef} style={{ marginTop: "1rem", marginBottom: "1rem", textAlign: 'center' }}>{errMsg}</Typography>}
                        <TextField
                            label="Username"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            placeholder="Enter Username"
                            required
                            onChange={(e) => setUser(e.target.value)}
                            InputProps={{ style: { fontSize: '18px' } }}
                        />
                        <TextField
                            label="Password"
                            variant="outlined"
                            fullWidth
                            type={showPwd ? "text" : "password"}
                            margin="normal"
                            placeholder="Enter Password"
                            required
                            onChange={(e) => setPwd(e.target.value)}
                            InputProps={{ style: { fontSize: '18px' }, endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleTogglePasswordVisibility} edge="end" tabIndex={-1}>
                                        {showPwd ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            }}
                        />
                        <Button variant="contained" color="primary" fullWidth type="submit" onClick={handleSubmit} style={{ marginTop: '1rem', fontSize: '18px' }}>Login</Button>
                    </form>
                    <Button
                        onClick={handleBack}
                        variant="outlined"
                        style={{ color: 'blue', width: '100%', height: '50px', borderRadius: '5px', marginTop: '1rem', borderColor: 'blue', fontSize: '18px' }}
                    >
                        Back
                    </Button>
                </Paper>
            </div>
            {popupMsg && (
                <div style={{
                    fontSize: '20px',
                    position: 'absolute',
                    bottom: '20px',
                    right: '20px',
                    background: '#4caf50',
                    color: '#fff',
                    padding: '0.5rem',
                    borderRadius: '5px',
                    zIndex: '999',
                }}>
                    {popupMsg}
                    <IconButton onClick={handlePopupClose} style={{ marginLeft: '10px', color: '#fff' }}>
                        <CloseIcon />
                    </IconButton>
                </div>
            )}
            {popupMsg2 && (
                <div style={{
                    fontSize: '20px',
                    position: 'absolute',
                    bottom: '20px',
                    right: '150px',
                    background: 'lightcoral',
                    color: '#fff',
                    padding: '0.5rem',
                    borderRadius: '5px',
                    zIndex: '999',
                }}>
                    {popupMsg2}
                    <IconButton onClick={handlePopupClose} style={{ marginLeft: '10px', color: '#fff' }}>
                        <CloseIcon />
                    </IconButton>
                </div>
            )}
        </div>
    );
};

export default Login;
