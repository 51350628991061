import { useMemo } from "react";
import "./first-div-product.css";

const FirstDivProduct = ({
  image8,
  // ethereum1,
  propWidth,
  firstDivProductPadding,
  firstDivProductOverflow,
  wrapperImage8AlignSelf,
  wrapperImage8Width,
  propBorderRadius,
  image8IconAlignSelf,
  image8IconOverflow,
  infoPadding,
  buyProduct1AlignSelf,
  buyProduct1Width,
  propPadding1,
  propDisplay,
  propFlex,
  propTextAlign,
  propMinWidth,
}) => {
  const firstDivProductStyle = useMemo(() => {
    return {
      width: propWidth,
      padding: firstDivProductPadding,
      overflow: firstDivProductOverflow,
    };
  }, [propWidth, firstDivProductPadding, firstDivProductOverflow]);

  const wrapperImage8Style = useMemo(() => {
    return {
      alignSelf: wrapperImage8AlignSelf,
      width: wrapperImage8Width,
      borderRadius: propBorderRadius,
    };
  }, [wrapperImage8AlignSelf, wrapperImage8Width, propBorderRadius]);

  const image8IconStyle = useMemo(() => {
    return {
      alignSelf: image8IconAlignSelf,
      overflow: image8IconOverflow,
    };
  }, [image8IconAlignSelf, image8IconOverflow]);

  const infoStyle = useMemo(() => {
    return {
      padding: infoPadding,
    };
  }, [infoPadding]);

  const buyProduct1Style = useMemo(() => {
    return {
      alignSelf: buyProduct1AlignSelf,
      width: buyProduct1Width,
      padding: propPadding1,
    };
  }, [buyProduct1AlignSelf, buyProduct1Width, propPadding1]);

  const buyProductStyle = useMemo(() => {
    return {
      display: propDisplay,
      flex: propFlex,
      textAlign: propTextAlign,
      minWidth: propMinWidth,
    };
  }, [propDisplay, propFlex, propTextAlign, propMinWidth]);

  return (
    <div className="first-div-product" style={firstDivProductStyle}>
      <div className="wrapper-image-8" style={wrapperImage8Style}>
        <img
          className="image-8-icon"
          loading="lazy"
          alt=""
          src={image8}
          style={image8IconStyle}
        />
      </div>
      <div className="info" style={infoStyle}>
        <div className="name-product1">
          <div className="cyber-samurai-575"></div>
          <div className="price-product1">
            {/* <img
              className="ethereum-icon"
              loading="lazy"
              alt=""
              src={ethereum1}
            /> */}
            <div className="div4"></div>
          </div>
        </div>
      </div>
      {/* <button className="buy-product1" style={buyProduct1Style}>
        <div className="buy-product" style={buyProductStyle}>
          Buy Product
        </div>
      </button> */}
    </div>
  );
};

export default FirstDivProduct;
